import { useEffect, useState } from "react";
import { faMinus, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../../../config";
import ButtonUPush from "../../../../../../components/ui/ButtonUPush";
import DeleteItem from "./DeleteItem";
import icNotFound from "../../../../../../assets/svg/ic_page_not_found.svg";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../../utils/constants";
import Variations from "../../NewSale/components/Variations";

const ActiveItem = ({...props}) => {
    const { t } = useTranslation();

    const [saleItem, setSaleItem] = useState({});
    const [quantity, setQuantity] = useState();

    useEffect(() => {
        setSaleItem(props.activeItem);
    }, [props.activeItem]);

    useEffect(() => {
        if (saleItem) setQuantity(saleItem.quantity);
    }, [saleItem]);

    useEffect(() => {
        if (saleItem) {
            if (quantity < 0 || (quantity > (saleItem.originalQuantity - saleItem.originalReturned))) {
                setQuantity(0);
                return;
            }
            props.setActiveItem({...saleItem, quantity});
        }
    }, [quantity]);

    const [modalDelete, setModalDelete] = useState(false);

    const handleQuantity = (event) => {
        event.preventDefault();
        props.setActiveItem({...props.activeItem, quantity})
    }

    return (
        <div className="col-md-3">
            {(saleItem && saleItem?.product) && <div className="row bg-white rounded-2 py-2 px-2 my-2">
                <div className="d-flex flex-row justify-content-between px-0">
                    <div className="d-flex flex-column">
                        <span className="font-16 medium fg-dark">{saleItem?.product?.name}</span>
                        <code className="font-12 fg-dark">{t('BAR_CODE')}: {saleItem?.product?.barcode}</code>
                        <Variations variationOptions={saleItem?.product?.variationOptions}/>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <span className="font-12 fg-dark">
                            {t('PRICE')}:
                            <span className="medium ms-1">
                                {saleItem?.product?.sellingPrice.toFixed(2)}
                            </span>
                        </span>
                        {saleItem?.product?.discount ? 
                        <span className="font-12 text-white bg-orange-400 rounded-1 text-center px-1">
                            {t('DISCOUNT')}:
                            <span className="medium ms-1">{saleItem?.product?.discount}%</span>
                        </span>
                        : <span></span>}
                    </div>
                </div>
                {saleItem?.product?.image ?
                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_PRODUCT_IMAGES}${saleItem?.product?.image}`} className="bg-white image-product"/>
                : <img src={icNotFound} className="image-product"/>
                }
                <div className="d-flex flex-row justify-content-between bg-light rounded-1 mt-1">
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('TOTAL')}</code>
                        <code className="font-14 medium fg-dark">
                            {(saleItem?.total + saleItem?.discount).toFixed(2)}
                        </code>
                    </div>
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('DISCOUNT')}</code>
                        <code className="font-14 medium fg-red-300">{saleItem?.discount?.toFixed(2)}</code>
                    </div>
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('COMMON')} {t('TOTAL')}</code>
                        <code className="font-16 medium fg-green-500">{saleItem?.total?.toFixed(2)}</code>
                    </div>
                </div>
                <span className="font-14 medium fg-dark mt-2">{t('QUANTITY')} [`]</span>
                <div className="d-flex flex-row align-items-center justify-content-between mt-2 mb-1 px-0">
                    <div className="d-flex flex-row align-items-center">
                        <ButtonUPush
                            onSubmit={() => setQuantity(quantity - 1)}
                            shadow={"bg-gray-300"} icon={faMinus} className={"bg-gray font-18 card-default"}/>
                         <form onSubmit={handleQuantity}>
                            <input type="number" step={'any'} value={quantity} onChange={(e) => setQuantity(e.target.value)} ref={props.inputQuantityRef} className="p-2 font-18 rounded-1 w-100 input-inner-shadow"/>
                        </form>
                        <ButtonUPush
                            onSubmit={() => setQuantity(quantity + 1)}
                            shadow={"bg-gray-300"} icon={faPlus} className={"bg-gray font-18 card-default"}/>
                    </div>
                    <ButtonUPush onSubmit={() => setModalDelete(true)}
                        shadow={"bg-red-700"} icon={faTrashCan} className={"bg-red-400 font-18 text-white card-defaul"}/>
                </div>
            </div>}
            <DeleteItem
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                item={saleItem}
                items={props.returnedSaleItems}
                setItems={props.setReturnedSaleItems}
            />
        </div>
    )
}

export default ActiveItem;