import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const FoundItems = ({...props}) => {
    const { t } = useTranslation();

    return (
        <ul className='stripped container-found-list-items'>
            { props?.items?.length > 0 &&
                <div className="d-flex flex-fill flex-row align-items-center font-12 medium px-2 bg-blue-900 text-white py-1">
                    <FontAwesomeIcon icon={faSearch} className="me-2"/>
                    <span className="">{t('SEARCH_RESULT')}</span>
                </div>
            }
            { props?.items?.map((item) => {
                return (
                    <li onMouseDown={() => props.setItem(item)}
                        className='d-flex align-items-center list-group-item list-group-item-light list-group-item-action sm m-0 px-2 py-1 hover-cursor' key={item?.id}>
                        <span className="small medium">{item?.name}</span>
                    </li>
                )
            })}
        </ul>
    );
}

export default FoundItems;