import { useState, useEffect } from "react";
import useAuth from '../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import FoundItems from "../../../../../../../components/form/FoundItems";
import ToastU from "../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const variation = props.item;

    const [variationTypeId, setVariationTypeId] = useState('');
    const [variationTypeName, setVariationTypeName] = useState('');
    const [variationType, setVariationType] = useState({});
    const [variationTypes, setVariationTypes] = useState([]);
    
    const [variationOptionId, setVariationOptionId] = useState('');
    const [variationOptionName, setVariationOptionName] = useState('');
    const [variationOption, setVariationOption] = useState({});
    const [variationOptions, setVariationOptions] = useState([]);

    const [queryVariationType, setQueryVariationType] = useState({
        limit:0, order: [['order', 'ASC']], filter: { name: '' }
    });

    const [queryVariationOption, setQueryVariationOption] = useState({
        limit:0, order: [['order', 'ASC']], filter: { variationTypeId: '', name: '' }
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (!variation?.variationOption?.id) return;
        setVariationTypeId(variation?.variationOption?.variationType?.id);
        setVariationOptionId(variation.variationOptionId);

        setVariationType(variation?.variationOption?.variationType);
        setVariationTypeName(variation?.variationOption?.variationType?.name);

        setVariationOption(variation?.variationOption);
        setVariationOptionName(variation?.variationOption?.name);
    }, [variation]);

    useEffect(() => {
        if (ready) {
            getVariationTypes();
        }
    }, [queryVariationType]);

    useEffect(() => {
        if (variationType.id) {
            getVariationOptions();
        }
    }, [queryVariationOption]);
    
    const handleVariationTypeInputOnFocus = () => {
        setQueryVariationType({...queryVariationType, limit: 20});
    };

    const handleVariationTypeInputOnBlur = () => {
        setVariationTypes([]);
    };
    
    const handleVariationOptionInputOnFocus = () => {
        setQueryVariationOption({...queryVariationOption, limit: 20, filter: {...queryVariationOption.filter, variationTypeId: variationType.id }});
    };

    const handleVariationOptionInputOnBlur = () => {
        setVariationOptions([]);
    };

    useEffect(() => {
        if (variationTypeName.length < 1) {
            setVariationTypes([]);
            return;
        }
        if (variationTypeName && variationType.id && variationType.name != variationTypeName)
            setQueryVariationType({...queryVariationType, limit: 50, filter: {...queryVariationType.filter,  name: variationTypeName }});
    }, [variationTypeName]);

    
    useEffect(() => {
        if (variationOptionName.length < 1) {
            setVariationOptions([]);
            return;
        }
        if (variationOptionName && variationOption.id && variationOption.name != variationOptionName)
            setQueryVariationOption({...queryVariationOption, limit: 50, filter: {...queryVariationOption.filter,  name: variationOptionName }});
    }, [variationOptionName]);


    useEffect(() => {
        if (!variationType.id) return;
        setVariationTypeName(variationType.name);
        setVariationTypeId(variationType.id);
        setVariationTypes([]);

        clearVariationOptionField();
        setQueryVariationOption({...queryVariationOption, limit: 0, filter: {...queryVariationOption.filter, variationTypeId: variationType.id }});
    }, [variationType]);

    useEffect(() => {
        if (!variationOption.id) return;
        setVariationOptionName(variationOption.name);
        setVariationOptionId(variationOption.id);
        setVariationOptions([]);
    }, [variationOption]);

    const getVariationTypes = () => {
        axiosPrivate.get('/variation-types', { params: queryVariationType }).then((res) => {
            if (res.status == 200) setVariationTypes(res.data.data);
        });
    }

    const getVariationOptions = () => {
        axiosPrivate.get('/variation-options', { params: queryVariationOption }).then((res) => {
            if (res.status == 200) setVariationOptions(res.data.data);
        });
    }

    const editTempInvoiceItem = () => {
        const requiredInputs = [
            { key: variationTypeId, value: t('VARIATION_TYPE') },
            { key: variationOptionId, value: t('VARIATION_OPTION') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const updatetVariations = props.variations.filter(item => item?.variationOption?.variationType?.id !== variation?.variationOption?.variationType?.id)

        props.setVariations(
            [...updatetVariations, {variationOption, variationOptionId }]
        );

        clearVariationTypeField();
        clearVariationOptionField();
        props.setModalShow(false);
    }
    
    const clearVariationTypeField = () => {
        setVariationType({});
        setVariationTypeName('');
        setVariationTypeId('');
    }

    const clearVariationOptionField = () => {
        setVariationOption({});
        setVariationOptionName('');
        setVariationOptionId('');
    }


    return (
        <ModalU show={props.modalShow} size={''} title={`${t('VARIATION')} [${t('EDIT')}]`}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editTempInvoiceItem} onHide={props.setModalShow}>
            <div className='row mx-2'>
                <div className="col-md-12">
                    <InputLabeled label={t('VARIATION_TYPE')} type={'text'} value={variationTypeName} setValue={setVariationTypeName} hint={t('NAME')} onFocus={handleVariationTypeInputOnFocus} onBlur={handleVariationTypeInputOnBlur} required={true}/>
                    <FoundItems items={variationTypes} setItem={setVariationType}/>
                </div>
                <div className="col-md-12 mt-2">
                    <InputLabeled label={t('VARIATION_OPTION')} type={'text'} value={variationOptionName} setValue={setVariationOptionName} hint={t('NAME')} onFocus={handleVariationOptionInputOnFocus} onBlur={handleVariationOptionInputOnBlur} required={true}/>
                    <FoundItems items={variationOptions} setItem={setVariationOption}/>
                </div>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default Edit