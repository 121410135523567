export default class Keys {
    // static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    // static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    // static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static PASSWORD_REGEX = /^[ -~]{3,255}$/;
    static NAME_REGEX = /^[\p{L}\p{N}\p{M}&.\s-]{3,255}$/u;
    static PHONE_NUMBER_REGEX = /^[6-7][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;
    static PHONE_CODE = "+993";

    static UNAUTHORIZED = "Unauthorized";
    static TMT = "TMT";

    // For App passwords / Roles
    static ADMINISTRATION = "Administration"; // Administration

    static ROOT = "ROOT"; // Root
    static ADMIN = "ADMIN"; // Admin
    static MNGR = "MNGR"; // Manager
    static CP = "CP"; // Cashier Panel
    static WH = "WH"; // Warehouse

    // Calculator

    static DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    static NUM_PADS = [['1', '2', '3'], ['4', '5', '6'], ['7', '8', '9'], ['.', '0', '<']];

    // ---------------------- LINKS ------------------------------------
    // Links Main
    static DASHBOARD_LINKS = [ '/' ];

    static PRODUCT_PRODUCTS_LINKS = [ '/product/products', '/product/products/:id', '/product/products/create' ];
    static PRODUCT_LINKS = this.PRODUCT_PRODUCTS_LINKS.concat();

    static INVOICE_INVOICES_LINKS = [ '/invoice/invoices', '/invoice/invoices/:id', '/invoice/invoices/create' ];
    static INVOICE_INVOICE_ITEMS_LINKS = [ '/invoice/invoice-items' ];
    static INVOICE_INVOICE_RETURNS_LINKS = [ '/invoice/invoice-returns' ];
    static INVOICE_LINKS = this.INVOICE_INVOICES_LINKS.concat(this.INVOICE_INVOICE_ITEMS_LINKS, this.INVOICE_INVOICE_RETURNS_LINKS);
    
    static WAREHOUSE_WAREHOUSES_LINKS = [ '/warehouse/warehouses' ];
    static WAREHOUSE_WRITTEN_OFF_PRODUCTS_LINKS = [ '/warehouse/written-off-products','/warehouse/written-off-products/create' ];
    static WAREHOUSE_AUDITS_LINKS = [ '/warehouse/audits', '/warehouse/audits/:id' ];
    static WAREHOUSE_LINKS = this.WAREHOUSE_WAREHOUSES_LINKS.concat(this.WAREHOUSE_WRITTEN_OFF_PRODUCTS_LINKS, this.WAREHOUSE_AUDITS_LINKS);

    static COMPANY_EXPENSES_LINKS = ['/company/expenses'];
    static COMPANY_SUPPLIERS_LINKS = [ '/company/suppliers' ];
    static COMPANY_CONTACTS_LINKS = [ '/company/contacts' ];
    static COMPANY_BRANCHES_LINKS = [ '/company/branches' ];
    static COMPANY_LINKS = this.COMPANY_EXPENSES_LINKS.concat(this.COMPANY_SUPPLIERS_LINKS, this.COMPANY_SUPPLIERS_LINKS, this.COMPANY_CONTACTS_LINKS, this.COMPANY_BRANCHES_LINKS);

    static FINANCE_REPORTS_LINKS = ['/finance/reports'];
    static FINANCE_SALES_LINKS = ['/finance/sales'];
    static FINANCE_SALE_ITEMS_LINKS = ['/finance/sale-items'];
    static FINANCE_RETURNED_SALES_LINKS = ['/finance/returned-sales'];
    static FINANCE_LINKS = this.FINANCE_REPORTS_LINKS.concat(this.FINANCE_SALES_LINKS, this.FINANCE_SALE_ITEMS_LINKS, this.FINANCE_RETURNED_SALES_LINKS);

    // Links Settings -------------------------------------------

    static SETTINGS_LINKS = [
        '/settings/profile',
        '/settings/privacy-and-security',
        '/settings/data',
    ];

    static SETTINGS_DATA_GENERAL_LINKS = [
        '/settings/data/general',
        '/settings/data/general/categories',
        '/settings/data/general/expense-categories',
        '/settings/data/general/brands',
        '/settings/data/general/units',
        '/settings/data/product/variation-types',
        '/settings/data/product/variation-options',
        '/settings/data/general/manufacturers',
        '/settings/data/general/countries',
        '/settings/data/general/provinces',
        '/settings/data/general/districts'
    ];

    static SETTINGS_DATA_ADMINISTRATION_LINKS = [
        '/settings/data/administration',
        '/settings/data/administration/users',
        '/settings/data/administration/users/create',
        '/settings/data/administration/users/:id',
        '/settings/data/administration/logs',
        '/settings/data/administration/roles',
        '/settings/data/administration/backup'
    ];

    static SETTINGS_DATA_LINKS = this.SETTINGS_DATA_GENERAL_LINKS.concat(this.SETTINGS_DATA_ADMINISTRATION_LINKS);

    // Links - Cashier Desk Nav bar
    static CASHIER_MAIN_NAV_BAR = [
        '/cp',
        '/cp/main',
        '/cp/sales',
        '/cp/sales/:id',
        '/cp/returned-sales',
        '/cp/returned-sales/:id',
        '/cp/contacts',
        '/cp/expenses',
    ];

    static CASHIER_ADMIN_NAV_BAR = ['/'];

    static MODULES = new Map([
        ['1000', 'MAIN'],
        ['1001', 'PRODUCT'],
        ['1002', 'INVOICE'],
        ['1003', 'WAREHOUSE'],
        ['1004', 'COMPANY'],
        ['1005', 'FINANCE'],
        ['1006', 'DATA_GENERAL'],
        ['1007', 'DATA_ADMINISTRATION'],
        ['1008', 'CASHIER_DESK'],
    ]);
}