import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import FinanceWrapper from "../FinanceWrapper";
import PrintSaleSummary from "../../../../../components/ui/PrintSaleSummary";
import { useTranslation } from "react-i18next";
import SelectDate from "./components/SelectDate";
import SelectCashier from "./components/SelectCashier";
import icPrinter from "../../../../../assets/svg/ic_printer.svg";
import Keys from "../../../../../utils/constants";
import Utils from "../../../../../utils";
import CardTotal from "./components/CardTotal";
import SalePieChart from "./chart/SalePieChart";
import Filter from "./Filter";

const Reports = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [cashiers, setCashiers] = useState([]);

    const [reportSale, setReportSale] = useState({});
    const [reportExpense, setReportExpense] = useState({});
    
    const [totalSale, setTotalSale] = useState();
    const [totalSaleCash, setTotalCash] = useState();
    const [totalSaleCard, setTotalCard] = useState();
    const [totalSaleDiscount, setTotalSaleDiscount] = useState();
    const [totalSaleRevenue, setTotalSaleRevenue] = useState();
    const [totalReturnedSale, setTotalReturnedSale] = useState();
    const [totalReturnedSaleRevenue, setTotalReturnedSaleRevenue] = useState();
    const [totalWrittenOff, setTotalWrittenOff] = useState();
    const [totalAudit, setTotalAudit] = useState();
    const [totalRevenue, setTotalRevenue] = useState();
    const [totalExpense, setTotalExpense] = useState();

    const today = new Date(Utils.getCurrentDateISO()).toISOString().split('T')[0];

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        order: parsed.order || [['createdAt', 'DESC']],
        filter: {
            id: parsed.time || 0,
            createdAtLb: parsed.calb || today,
            createdAtUb: parsed.caub || '',
            cashierId: parsed.cashier || ''
        }
    }
    const [query, setQuery] = useState(urlQuery);

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/reports' } ,
        { title: t('REPORTS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getCashiers();
        }
    }, [ready]);

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getReportSale();
            getReportExpense();
        }
    }, [query, ready]);

    useEffect(() => {
        if (!reportSale) return;

        setTotalSale(reportSale?.sale?.total);
        setTotalCash(reportSale?.sale?.cash);
        setTotalCard(reportSale?.sale?.card);
        setTotalSaleDiscount(reportSale?.sale?.discount);
        setTotalSaleRevenue(reportSale?.sale?.revenue);
        setTotalReturnedSale(reportSale?.returnedSale?.total);
        setTotalReturnedSaleRevenue(reportSale?.returnedSale?.revenue);
        setTotalWrittenOff(reportSale?.writtenOff?.total);
        setTotalAudit(reportSale?.audit?.total);
        setTotalRevenue(reportSale?.sale?.revenue - reportSale?.returnedSale?.revenue - reportSale?.writtenOff?.total - reportSale?.audit?.total);
    }, [reportSale]);

    useEffect(() => {
        if (!reportExpense) return;

        setTotalExpense(reportExpense?.expense?.total);
    }, [reportExpense]);

    const getUpdatedUrl = () => {
        parsed.time = query.filter.id;
        parsed.calb = query.filter.createdAtLb;
        parsed.caub = query.filter.createdAtUb;
        parsed.cashier = query.filter.cashierId;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getReportSale = () => {
        setLoading(true);
        axiosPrivate.get('/reports/sale', { params: query }).then((res) => {
            if (res.status == 200) {
                setReportSale(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const getReportExpense = () => {
        setLoading(true);
        axiosPrivate.get('/reports/expense', { params: query }).then((res) => {
            if (res.status == 200) {
                setReportExpense(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const getCashiers = () => {
        setLoading(true);
        // const cashierQuery = {filter: { role: Keys.CASHIER}}
        axiosPrivate.get('/users').then((res) => {
            if (res.status == 200) {
                setCashiers(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const handlePrint = () => { window.print() }

    return (
        <div>
            <div className="hide-on-print">
                <FinanceWrapper loading={loading} breadcrumbs={breadcrumbs}>
                    <div className="head-long-page">
                        <span className="fs-5 bold fg-dark">{t('REPORTS')}</span>
                        <div className="d-flex align-items-center"></div>
                    </div>
                    <div className="mt-4"></div>
                    <div className="d-flex flex-row justify-content-between mt-4 mx-2">
                        <div className="d-flex flex-row">
                            <SelectDate query={query} setQuery={setQuery}/>
                            <SelectCashier items={cashiers} value={''} query={query} setQuery={setQuery}/>
                            <Filter query={query} setQuery={setQuery} cashiers={cashiers}/>
                            <span onClick={handlePrint} className="bg-light border bg-gradient rounded-2 me-2 p-2">
                                <img src={icPrinter} alt="printer" className="wh-25 hover-cursor"/>
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 mt-3">
                        <CardTotal title={t('SALE')} value={totalSale} link={'/finance/sales'}/>
                        <CardTotal title={`${t('RETURNED')} ${t('SALE')}`} value={totalReturnedSale} link={'/finance/returned-sales'}/>
                        <CardTotal title={t('WRITTEN_OFF_PRODUCTS')} value={totalWrittenOff} link={'/warehouse/written-off-products'}/>
                        <CardTotal title={t('AUDIT')} value={totalAudit} link={'/warehouse/audits'}/>
                    </div>
                    <div className="row mx-0 mt-4">
                        <SalePieChart
                            title={`${t('SALE')} - ${t('CASH')} / ${t('CARD')}`}
                            palette={'palette6'}
                            series={[
                                parseFloat(totalSaleCash?.toFixed(2)) || 0,
                                parseFloat(totalSaleCard?.toFixed(2)) || 0
                            ]}
                            labels={[
                                `${t('CASH')} - ${totalSaleCash?.toFixed(2)}`,
                                `${t('CARD')} - ${totalSaleCard?.toFixed(2)}`
                            ]}/>
                        <SalePieChart
                            title={`${t('SALE')} - ${t('REVENUE')} / ${t('LOSS')}`}
                            palette={'palette7'}
                            series={[
                                parseFloat((totalWrittenOff+totalAudit)?.toFixed(2)) || 0,
                                parseFloat((totalSaleRevenue-totalReturnedSaleRevenue)?.toFixed(2)) || 0
                            ]}
                            labels={[
                                `${t('LOSS')} - ${(totalWrittenOff+totalAudit)?.toFixed(2)}`,
                                `${t('REVENUE')} - ${(totalSaleRevenue-totalReturnedSaleRevenue)?.toFixed(2)}`
                            ]}/>
                    </div>
                    <div className="row mx-0 mt-4">
                        <SalePieChart
                            title={`${t('AUDIT')} / ${t('WRITTEN_OFF_PRODUCTS')} / ${t('DISCOUNT')}`}
                            palette={'palette1'}
                            series={[
                                parseFloat(totalAudit?.toFixed(2)) || 0,
                                parseFloat(totalWrittenOff?.toFixed(2)) || 0,
                                parseFloat(totalSaleDiscount?.toFixed(2)) || 0
                            ]}
                            labels={[
                                `${t('AUDIT')} - ${totalAudit?.toFixed(2)}`,
                                `${t('WRITTEN_OFF_PRODUCTS')} - ${(totalWrittenOff)?.toFixed(2)}`,
                                `${t('DISCOUNT')} - ${totalSaleDiscount?.toFixed(2)}`
                            ]}/>
                        
                        <div className="col-md-6">
                            <div className="d-flex flex-column p-3 shadow-sm" style={{height: "238px"}}>
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fs-5 fg-dark">{`${t('TOTAL')} ${t('EXPENSE')}`}</span>
                                    <span className={`fs-5 medium fg-red-300`}>{totalExpense?.toFixed(2)} {Keys.TMT}</span>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fs-5 fg-dark">{`${t('TOTAL')} ${totalRevenue >= 0 ? t('REVENUE') : t('LOSS')}`}</span>
                                    <span className={`fs-5 medium ${totalRevenue >= 0 ? 'fg-green-500' : 'fg-red-300'}`}>{totalRevenue?.toFixed(2)} {Keys.TMT}</span>
                                </div>
                                <div className="d-flex flex-column mt-3">
                                    <span className="fs-3 medium fg-dark">{`${t('TOTAL')}`}</span>
                                    <span className={`fs-1 bold ${(totalRevenue-totalExpense) >= 0 ? 'fg-green-500' : 'fg-red-300'}`}>{(totalRevenue-totalExpense)?.toFixed(2)} {Keys.TMT}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </FinanceWrapper>
            </div>
            <PrintSaleSummary report={reportSale} query={query}/>
        </div>
    );
}

export default Reports;