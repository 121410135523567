import Wrapper from "../../layout/Wrapper"
import SideBar from "../../layout/SideBar";
import NavBar from "../../layout/NavBar";
import OpenSales from "../../layout/OpenSales";
import icLogo from "./../../../../assets/svg/ic_logo.png";
import { useTranslation } from "react-i18next";
import DropdownLang from "./../../../../layouts/DropdownLang";

const HomeWrapper = (props) => {
    const { t } = useTranslation();

    const items = [
        { name: t('CASHIER_DESK'), id: "cashier_desk", links: ['/cp', '/cp/contacts', '/cp/expenses']},
        { name: t('SALES'), id: "sales", links: ['/cp/sales', '/cp/sales/:id']},
        { name: `${t('RETURNED')} ${t('SALES')}`, id: "returned_sales", links: ['/cp/returned-sales'] },
    ];

    return (
        <Wrapper>
            <div className="cp-home">
                <div className="d-flex flex-row justify-content-between">
                    <img src={icLogo} className="wh-50 mt-2 ms-3" />
                    <DropdownLang className={'mt-2'}/>
                </div>
                <NavBar/>
                <div className="cp-main-page">
                    <SideBar items={items}/>
                    <div className="cp-body-page">
                        { props.children }
                    </div>
                </div>
            </div>
            <OpenSales />
        </Wrapper>
    );
};

export default HomeWrapper;