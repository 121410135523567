import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";

const Variations = ({variationOptions}) => {
    const { t } = useTranslation();
   
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div className={`${variationOptions?.length > 0 ? 'medium bg-purple-50 fg-purple-300 rounded-1 small hover-cursor my-1 py-1 px-2' : 'hide'}`}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}>
            <FontAwesomeIcon icon={faSwatchbook} className="me-2"/>
            {t('VARIATIONS')}
            <FontAwesomeIcon icon={faChevronDown} className="ms-2 small-x"/>
        </div>
      ));

    return (
        <Dropdown className="large">
            <Dropdown.Toggle as={CustomToggle}/>
            <Dropdown.Menu className="bg-light px-2" style={{ minWidth: '120px' }}>
                { variationOptions?.map((variationOption) => {
                    return(
                        <ListGroup.Item key={variationOption?.id} className="d-flex align-items-center justify-content-between small">
                            <span className="medium fg-dark">{variationOption?.variationType?.name}</span>
                            <span>{variationOption?.name}</span>
                        </ListGroup.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Variations;