import React from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import icGlobe from './../assets/svg/ic_globe.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const DropdownLang = ({...props}) => {
  const { i18n } = useTranslation();
  const locale = localStorage.getItem('locale');
  const selected = locale ? JSON.parse(locale) : { lng: 'tk', code: 'TM'}; 
  const [key, setKey] = React.useState(0); // State to trigger re-render

  const changeLanguage = (lng, code) => {
      i18n.changeLanguage(lng);
      const locale = {lng, code};
      localStorage.setItem('locale', JSON.stringify(locale));
      setKey(prevKey => prevKey + 1); // Force update
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className={`bg-light rounded-2 px-2 py-1 hover-cursor me-3 ${props.className}`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      <img src={icGlobe} className="wh-15 me-2" />
      <span className="font-14 fg-dark bold ">{selected.code}</span>
      <FontAwesomeIcon icon={faAngleDown} className="small-x ms-2 fg-gray"/>
    </div>
  ));

  return (
    <Dropdown className="large" key={key}>
      <Dropdown.Toggle as={CustomToggle}/>
      <Dropdown.Menu className="bg-light" style={{ minWidth: '120px' }}>
        <Nav className="d-flex flex-row align-items-center">
          <Nav.Link onClick={() => changeLanguage('tk', 'TM')} className="bold ms-1 small py-0 px-2 lang-item">TM</Nav.Link>
          <Nav.Link onClick={() => changeLanguage('ru', 'RU')} className="bold ms-1 small py-0 px-2 lang-item">RU</Nav.Link>
          <Nav.Link onClick={() => changeLanguage('en', 'EN')} className="bold ms-1 small py-0 px-2 lang-item">EN</Nav.Link>
        </Nav>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownLang;