import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faAdd, faCircleArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import HomeWrapper from "../HomeWrapper";
import TableStd from '../../../../../components/ui/TableStd';
import InputField from "../../../../../components/form/InputField";
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from "../../../../../components/ui/SortU";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import Actions from "../../../../../components/ui/Actions";
import Create from '../../../../Main/pages/Company/Expenses/Create';
import Update from '../../../../Main/pages/Company/Expenses/Update';
import Keys from "../../../../../utils/constants";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import ToastU from "../../../../../components/ui/ToastU";
import ButtonU from "../../../../../components/ui/ButtonU";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../../../../utils";

const Expenses = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    
    const [expenses, setExpenses] = useState([]);
    const [expensesCount, setExpensesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);
    
    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getExpenses();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getExpenses = () => {
        axiosPrivate.get('/expenses', { params: query }).then((res) => {
            if (res.status == 200) {
                setExpenses(res.data.data);
                setExpensesCount(res.data.count);
            }
        });
    }

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: {...prev.filter, number: find}}
        });
    }, [find]);

    const createNew = () => { setModalCreate(true); }

    const modelProps = [ 
        { 
            name: <SortU title={t('EXPENSE_CATEGORY')} attr={'expenseCategoryId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.expenseCategory?.parentCategory && '/'}{prop?.expenseCategory?.parentCategory?.name}/{prop?.expenseCategory?.name}</>) } 
        },
        {
            name: <SortU title={t('AMOUNT')} attr={'amount'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.amount?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('DEFINITION')} attr={'description'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.description}</>) }
        },
        {
            name: <SortU title={t('RESPONSIBLE')} attr={'userId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.user?.username || '-'}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.createdAt)}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <HomeWrapper>
            <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex flex-row">
                    <span className="btn-back me-1" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faCircleArrowLeft} className="fs-3"/>
                    </span>
                    <span className="bold fs-3 fg-dark ms-1">{t('EXPENSES')}</span>
                </div>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('FIND')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { expensesCount ?
                <div className="mt-2">
                    <TableStd modelProps={modelProps} data={expenses} count={expensesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={expensesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </HomeWrapper>
    );
}

export default Expenses;