import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import HomeWrapper from "../HomeWrapper";
import TableStd from '../../../../../components/ui/TableStd';
import InputField from "../../../../../components/form/InputField";
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from "../../../../../components/ui/SortU";
import Status from "../../../../../components/ui/Status";
import Filter from "./components/Filter";
import { useTranslation } from "react-i18next";
import PaymentTypes from "../../../../../components/ui/PaymentTypes";
import Actions from "../../../../../components/ui/Actions";
import { faEye } from "@fortawesome/free-regular-svg-icons";

const Sales = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const PAYMENT_TYPES = PaymentTypes();
    
    const [sales, setSales] = useState([]);
    const [salesCount, setSalesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);
    
    const [find, setfind] = useState('');

    useEffect(() => {
        if (ready) {
            setQuery(prev => {
                return {...prev, filter: {...prev.filter, cashierId: auth?.user?.id}}
            });
        }
    }, [ready]);

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getSales();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getSales = () => {
        if (!query?.filter?.cashierId) return;
        axiosPrivate.get('/sales', { params: query }).then((res) => {
            if (res.status == 200) {
                setSales(res.data.data);
                setSalesCount(res.data.count);
            }
        });
    }

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: {...prev.filter, number: find}}
        });
    }, [find]);

    const modelProps = [ 
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/cp/sales/${prop.id}`} className='no-decor'>{prop.number}</Link>) } 
        },
        {
            name: <SortU title={t('CLOSED')} attr={'closed'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: <SortU title={t('PAYMENT_TYPE')} attr={'paymentType'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{PAYMENT_TYPES.get(prop.paymentType)}</>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: <SortU title={t('NOTE')} attr={'note'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.note}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-500 font-14",
                        onClick: () => { navigate(`/cp/sales/${prop.id}`) },
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <HomeWrapper>
            <div className="d-flex align-items-center justify-content-between ">
                <span className="bold fs-3 fg-dark">{t('SALES')}</span>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                </div>
            </div>
            { salesCount ?
                <div className="mt-2">
                    <TableStd modelProps={modelProps} data={sales} count={salesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={salesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </HomeWrapper>
    );
}

export default Sales;