import { useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import SelectLabeled from '../../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [name, setName] = useState('');
    const [order, setOrder] = useState(1);
    const [variationTypeId, setVariationTypeId] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: variationTypeId, value: t('VARIATION_TYPE') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, order, variationTypeId };
        axiosPrivate.post('/variation-options/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('VARIATION_OPTION')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row">
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                <SelectLabeled label={t('VARIATION_TYPE')} items={props.variationTypes} value={variationTypeId} setValue={setVariationTypeId} className={'col-md-6'} required={true}/>
                <InputLabeled label={t('ORDER')} type={'number'} value={order} setValue={setOrder} hint={t('HINT_ORDER')} className={'col-md-6'}/>
            </form>
        </ModalU>
    );
}

export default Create