import { useState, useEffect } from "react";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import HomeWrapper from '../../pages/Main/HomeWrapper';
import ToastU from "../../../../components/ui/ToastU";
import FindProduct from "../../components/ui/FindProduct";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faCartPlus, faMagnifyingGlass, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-regular-svg-icons";

const Main = () => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [modalFindProduct, setModalFindProduct] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'F2': newSale(); break;
                case 'F8': newReturn(); break;
                case 'F4': setModalFindProduct(true); break;
                default: break;
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown) };
    }, []);

    const newSale = () => {
        axiosPrivate.post('/sales/create').then((res) => {
            if (res.status == 200) {
                const sale = res.data.data;
                navigate(`/cp/sales/open/${sale.id}`);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    const newReturn = () => {
        navigate('/cp/new-sale-item-return');
    }

    return (
        <HomeWrapper>
            <div className='row mx-0'>
                <div className='col-md-4'>
                    <div onClick={newSale} className='btn-card-8-col bg-blue-500 px-4 text-white'>
                        <div className="d-flex flex-row justify-content-between mb-2 fs-4 bold">
                            <span>{t('NEW_SALE')}</span>
                            <code className="text-white">[F2]</code>
                        </div>
                        <FontAwesomeIcon icon={faCartPlus} className="fs-2"/>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div onClick={newReturn} className='btn-card-8-col bg-purple-400 px-4 text-white'>
                        <div className="d-flex flex-row justify-content-between mb-2 fs-5 bold">
                            <span>{t('RETURN')}</span>
                            <code className="text-white">[F8]</code>
                        </div>
                        <FontAwesomeIcon icon={faArrowRotateLeft} className="font-26"/>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div onClick={() => {setModalFindProduct(true)}} className='btn-card-8-col bg-lime-400 px-4 fg-dark'>
                        <div className="d-flex flex-row justify-content-between mb-2 fs-5 bold">
                            <span>{t('PRODUCT')} {t('FIND')}</span>
                            <code className="fg-dark">[F4]</code>
                        </div>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="font-26"/>
                    </div>
                </div>
                <div className='col-md-2'>
                    <div onClick={() => {navigate('/cp/contacts')}} className='btn-card-8-col bg-gray px-4 fg-dark'>
                        <div className="d-flex flex-row justify-content-between mb-2 fs-5 bold">
                            <span>{t('CONTACTS')}</span>
                            <code className="fg-dark"></code>
                        </div>
                        <FontAwesomeIcon icon={faAddressBook} className="font-26"/>
                    </div>
                </div>
                <div className='col-md-2'>
                <div onClick={() => {navigate('/cp/expenses')}} className='btn-card-8-col bg-gray px-4 fg-dark'>
                    <div className="d-flex flex-row justify-content-between mb-2 fs-5 bold">
                        <span>{t('EXPENSES')}</span>
                        <code className="fg-dark"></code>
                    </div>
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="font-26"/>
                </div>
                </div>
                <div className='col-md-2'>
                    <div className='btn-card-8-col bg-gray'>
                        <span className='fs-5 bold'>{"Option 6"}</span>
                    </div>
                </div>
            </div>
            <FindProduct
                modalShow={modalFindProduct}
                setModalShow={setModalFindProduct}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </HomeWrapper>
    );
}

export default Main;