import { useState } from 'react';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import TableStd from '../../../../../../../components/ui/TableStd';
import ModalU from "../../../../../../../components/ui/ModalU";
import Edit from './Edit';
import { useTranslation } from "react-i18next";
import Actions from '../../../../../../../components/ui/Actions';

const Variations = ({...props}) => {
    const { t } = useTranslation();
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const deleteItem = () => {
        const updated = props.variations.filter(item => item.variationOptionId !== itemToBe.variationOptionId)
        props.setVariations(updated);
        setModalDelete(false);
    }

    const modelProps = [ 
        { 
            name: t('VARIATION_TYPE'),
            value: (prop) => { return(<>{prop?.variationOption?.variationType?.name}</>) } 
        },
        { 
            name: t('VARIATION_OPTION'),
            value: (prop) => { return(<>{prop?.variationOption?.name}</>) } 
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-13",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalEdit(true);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: "fg-red-300 font-13",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <div style={{overflowY: "auto", maxHeight: "250px"}}>
            {props?.variations?.length > 0 && <TableStd modelProps={modelProps} data={props?.variations}/>}
            { itemToBe.variationOption &&
                <ModalU show={modalDelete} title={t('WARNING')}
                    actionbtntext={t('DELETE')}
                    actionbtnclassname={"bg-red-400 font-14 medium text-white"}
                    onSubmit={deleteItem} onHide={setModalDelete}>
                    <div className='d-flex flex-column align-items-center justify-content-center p-3'>
                        <span className='medium text-danger mb-2'>{t('DATA_DELETION_CONFIRMATION')}</span>
                        <div className="d-flex flex-row mt-2">
                            <span className="medium fg-dark me-2">{t('VARIATION')}:</span>
                            <span className="fg-dark me-1">{itemToBe?.variationOption?.variationType?.name} = {itemToBe?.variationOption?.name}</span>
                        </div>
                    </div>
                </ModalU>
            }
            { itemToBe.variationOption && 
                <Edit
                    modalShow={modalEdit}
                    setModalShow={setModalEdit}
                    item={itemToBe}
                    variations={props.variations}
                    setVariations={props.setVariations}
                />
            }
        </div>
    )
}

export default Variations;