import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCancel, faCloudArrowUp, faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import Config from "../../../../../../config";
import ProductsWrapper from "../../ProductWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import InputLabeled from "../../../../../../components/form/InputLabeled";
import SelectLabeled from "../../../../../../components/form/SelectLabeled";
import SwitchLabeled from "../../../../../../components/form/SwitchLabeled";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";
import FoundCategories from "../../../../../Settings/components/FoundCategories";
import CategoryPath from "../../../../../Settings/components/CategoryPath";
import Variations from "./Variations";
import CreateVariation from "./Variations/Create";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProductCreate = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [image, setImage] = useState('');
    const [file, setFile] = useState();
    
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [category, setCategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryPath, setCategoryPath] = useState([]);
    const [disableCategoryInput, setDisableCategoryInput] = useState(false);

    const [brandId, setBrandId] = useState('');
    const [manufacturerId, setManufacturerId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [unitId, setUnitId] = useState('');
    const [discount, setDiscount] = useState();
    const [active, setActive] = useState(true);
    const [mostSold, setMostSold] = useState(false);
    const [description, setDescription] = useState();

    const [modalCancel, setModalCancel] = useState(false);
    const [variationModal, setVariationModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);

    const [variations, setVariations] = useState([]);

    const [query, setQuery] = useState({ order: [['name', 'ASC']] });

    const [queryCategory, setQueryCategory] = useState({
        limit:0,
        order: [['createdAt', 'ASC']],
        filter: { name: '', parentId: '' }
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('PRODUCT'), link: '/product/products' },
        { title: t('PRODUCTS'), link: '/product/products' },
        { title: t('NEW'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getCountries();
            getManufacturers();
            getBrands();
            getUnits();
        }
    }, [ready]);

    useEffect(() => {
        if (!ready) return;
        getCategories();
    }, [queryCategory]);

    const handleInputOnFocus = () => {
        setQueryCategory({...queryCategory, limit: 20});
    };

    const handleInputOnBlur = () => {
        setCategories([]);
    };

    useEffect(() => {
        if (categoryName?.length < 1) {
            setCategories([]);
            setQueryCategory({...queryCategory, limit: 0, filter: {...queryCategory.filter, name: ''}});
            return;
        }
        if (categoryName && category?.name != categoryName)
            setQueryCategory({...queryCategory, limit: 50, filter: {...queryCategory.filter, name: categoryName }});
    }, [categoryName]);

    useEffect(() => {
        if (!category?.id) return;
        setCategoryName(category?.name);
        setCategoryId(category?.id);
        setCategories([]);

        setCategoryPath([...categoryPath, category]);

        if (category?.subcategories?.length) {
            setQueryCategory({...queryCategory, limit: 50, filter: {...queryCategory.filter, parentId: category?.id }});
        }
        else {
            setQueryCategory({...queryCategory, limit: 0, filter: {...queryCategory.filter, parentId: '' }});
            setDisableCategoryInput(true);
        }
    }, [category]);

    const clearCategoryPath = () => {
        setCategoryPath([]);
        setQueryCategory({...queryCategory, limit: 0, filter: {...queryCategory.filter, parentId: '', name: ''}});
        setCategory({});
        setCategoryId('');
        setCategoryName('');
        setDisableCategoryInput(false);
    }

    const getCategories = () => {
        axiosPrivate.get('/categories', { params: queryCategory }).then((res) => {
            if (res.status == 200) setCategories(res.data.data);
        });
    }

    const getCountries = () => {
        axiosPrivate.get('/countries', { params: query }).then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        });
    }

    const getManufacturers = () => {
        axiosPrivate.get('/manufacturers', { params: query }).then((res) => {
            if (res.status == 200) setManufacturers(res.data.data);
        });
    }

    const getBrands = () => {
        axiosPrivate.get('/brands', { params: query }).then((res) => {
            if (res.status == 200) setBrands(res.data.data);
        });
    }

    const getUnits = () => {
        const query = { order: [['order', 'ASC']] };
        axiosPrivate.get('/units', { params: query }).then((res) => {
            if (res.status == 200) {
                setUnits(res.data.data);
                setUnitId(res.data.data[0].id);
            }
        });
    }

    const handleFile = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: barcode, value: t('BARCODE_REQUIRED') },
            { key: unitId, value: t('UNIT_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);

        const data = { 
            name, barcode, description, categoryId, brandId, manufacturerId, countryId, unitId, discount, active, mostSold, variations,
            path: Config.PATH_PRODUCT_IMAGES, 
            file };

        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.post('/products/create', data, {headers}).then((res) => {
            if (res.status === 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    return (
        <ProductsWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('NEW')} {t('PRODUCT')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} className={'bg-red-400 bg-gradient text-white'} icon={faCancel} text={t('CANCEL')}/>
                    <ButtonU onSubmit={handleSubmit} className={'bg-green-700 bg-gradient text-white'} icon={faSave} text={t('SAVE')}/>
                </div>
            </div>
            <div className="row mt-5 p-3 mx-5">
                <div className="row mx-0 col-md-8">
                    <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} className={'col-md-8'} required={true}/>
                    <InputLabeled label={t('BAR_CODE')} type={'text'} value={barcode} setValue={setBarcode} hint={'123456789'} className={'col-md-4'} required={true}/>
                    <CategoryPath categoryPath={categoryPath} clearCategoryPath={clearCategoryPath} className={'mt-3'}/>
                    <div className="col-md-6">
                        <InputLabeled label={t('CATEGORY')} type={'text'} value={categoryName} setValue={setCategoryName} hint={`${t('CATEGORY')} ${t('FIND')}`} onFocus={handleInputOnFocus} onBlur={handleInputOnBlur} disabled={disableCategoryInput} required={true}/>
                        <FoundCategories items={categories} setItems={setCategories} setItem={setCategory}/>
                    </div>
                    <SelectLabeled label={t('BRAND')} items={brands} value={brandId} setValue={setBrandId} className={'col-md-6'}/>
                    <SelectLabeled label={t('MANUFACTURER')} items={manufacturers} value={manufacturerId} setValue={setManufacturerId} className={'col-md-6'}/>
                    <SelectLabeled label={t('COUNTRY')} items={countries} value={countryId} setValue={setCountryId} className={'col-md-6'}/>
                    <SelectLabeled label={t('UNIT')} items={units} value={unitId} setValue={setUnitId} className={'col-md-6'} required={true}/>
                    <InputLabeled label={`${t('DISCOUNT')} (%)`} type={'number'} value={discount} setValue={setDiscount} hint={'5'} className={'col-md-6'}/>
                    <SwitchLabeled label={t('ACTIVE')} value={active} setValue={setActive} size="small" className={'col-md-6'} required={false}/>
                    <SwitchLabeled label={t('MOST_SOLD')} value={mostSold} setValue={setMostSold} size="small" className={'col-md-6'} required={false}/>
                
                    <div className="col-md-12 mt-3">
                        <Accordion elevation={1}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-purple-50">
                                <span className="col-md-12 fg-purple-300 bold font-14"><FontAwesomeIcon icon={faSwatchbook} className="me-2"/>{`${t('VARIATIONS')} ${variations?.length > 0 ? `(${variations?.length})` : ''}`}</span>
                            </AccordionSummary>
                            <AccordionDetails className="">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <span className="font-16 medium fg-dark">{t('TOTAL')}: {variations?.length}</span>
                                        <ButtonU onSubmit={() => setVariationModal(true)} icon={faAdd} className={'bg-blue-700 bg-gradient py-1 px-2 text-white'}/>
                                    </div>
                                    <Variations variations={variations} setVariations={setVariations}/>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <InputAreaLabeled label={t('DEFINITION')} type={'text'} value={description} setValue={setDescription} rows={5} hint={t('DEFINITION')} className={'col-md-12 mt-3'}/>
                </div>
                <div className="col-md-4">
                    <div className="d-flex flex-column my-2">
                        {image ? <img src={image} className="image-product mt-2"/>
                        :
                        <div className="image-upload">
                            <FontAwesomeIcon icon={faCloudArrowUp} className="fs-1 fg-blue-300"/>
                            <span className="medium fg-blue-300 mt-2">{t('IMAGE')} {t('UPLOAD')}</span>
                            <span className="fg-blue-700 font-12 mt-4 mx-3 text-center">{t('IMAGE_TO_BE_UPLOADED_SIZE')}</span>
                        </div>
                        }
                    </div>
                    <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn bg-blue-100 mt-1">
                        {t('IMAGE')} {t('UPLOAD')}
                        <input id="uploadBtn" type="file" hidden/>
                    </label>
                </div>
            </div>
            <CreateVariation
                variations={variations}
                setVariations={setVariations}
                modalShow={variationModal}
                setModalShow={setVariationModal}
            />
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </ProductsWrapper>
    );
}

export default ProductCreate;