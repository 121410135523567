import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import Keys from "../../../../../utils/constants";
import FinanceWrapper from "../FinanceWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import ShowSaleItem from "./../components/ShowSaleItem";
import Actions from "../../../../../components/ui/Actions";

const SaleItems = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [saleItems, setSaleItems] = useState([]);
    const [saleItemsCount, setSaleItemsCount] = useState();

    const [modalShowItem, setModalShowItem] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/reports' } ,
        { title: t('SALE_ITEMS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getSaleItems();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getSaleItems = () => {
        setLoading(true);
        axiosPrivate.get('/sale-items', { params: query }).then((res) => {
            if (res.status == 200) {
                setSaleItems(res.data.data);
                setSaleItemsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { productName: find }});
    }, [find]);


    const refresh = () => { getSaleItems() }
    
    const modelProps = [ 
        { 
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.product?.id}`} className='no-decor'>{prop?.product?.name}</Link>) } 
        },
        {
            name: t('SALE_NO'),
            value: (prop) => { return(<Link to={`/finance/sales/${prop?.sale?.id}`} className='no-decor'>{prop?.sale?.number}</Link>) } 
        },
        {
            name: <SortU title={t('QUANTITY')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: <SortU title={t('UNIT_PRICE')} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('SELLING_PRICE')} attr={'sellingPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.sellingPrice?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('TOTAL')} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.total?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('DISCOUNT')} attr={'discount'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<span className="fg-orange-300">{prop?.discount?.toFixed(2)}</span>) }
        },
        {
            name: <SortU title={t('REVENUE')} attr={'revenue'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<span className={`${prop?.revenue < 0 ? 'fg-red-300' : 'fg-green-500'}`}>{prop?.revenue?.toFixed(2)}</span>) }
        },
        {
            name: <SortU title={t('RETURNED')} attr={'returned'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.returned.toFixed(2)} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.createdAt)}</>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.updatedAt)}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalShowItem(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <FinanceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('SALE_ITEMS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                </div>
            </div>
            { saleItemsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={saleItems} count={saleItemsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={saleItemsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ShowSaleItem
                modalShow={modalShowItem}
                setModalShow={setModalShowItem}
                item={itemToBe}
            />
        </FinanceWrapper>
    );
}

export default SaleItems;