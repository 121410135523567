import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faArrowRotateRight, faAdd, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import Keys from "../../../../../utils/constants";
import CompanyWrapper from "../CompanyWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import Create from "./Create";
import Update from "./Update";
import ToastU from "../../../../../components/ui/ToastU";
import Actions from "../../../../../components/ui/Actions";

const Expenses = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [expenses, setExpenses] = useState([]);
    const [expensesCount, setExpensesCount] = useState();

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/reports' } ,
        { title: t('EXPENSES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getExpenses();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getExpenses = () => {
        setLoading(true);
        axiosPrivate.get('/expenses', { params: query }).then((res) => {
            if (res.status == 200) {
                setExpenses(res.data.data);
                setExpensesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: {...query.filter, expenseCategoryName: find }});
    }, [find]);

    const createNew = () => { setModalCreate(true); }

    const refresh = () => { getExpenses() }
    
    const modelProps = [ 
        { 
            name: <SortU title={t('EXPENSE_CATEGORY')} attr={'expenseCategoryId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.expenseCategory?.parentCategory && '/'}{prop?.expenseCategory?.parentCategory?.name}/{prop?.expenseCategory?.name}</>) } 
        },
        {
            name: <SortU title={t('AMOUNT')} attr={'amount'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.amount?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('DEFINITION')} attr={'description'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.description}</>) }
        },
        {
            name: <SortU title={t('RESPONSIBLE')} attr={'userId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.user?.username || '-'}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.createdAt)}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <CompanyWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('EXPENSES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('FIND')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { expensesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={expenses} count={expensesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={expensesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </CompanyWrapper>
    );
}

export default Expenses;