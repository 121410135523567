import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSwatchbook } from "@fortawesome/free-solid-svg-icons";

const Variations = ({variationOptions}) => {
    const { t } = useTranslation();
   
    return (
        <div className="d-flex flex-column">
            { variationOptions?.length > 0 && <span className="medium bg-purple-50 fg-purple-300 font-16 mt-4 py-2 px-3"><FontAwesomeIcon icon={faSwatchbook} className="me-2"/>{t('VARIATIONS')}</span>}
            { variationOptions?.map((variationOption) => {
                return(
                    <ListGroup.Item key={variationOption?.id} className="d-flex align-items-center justify-content-between py-1">
                        <span className="medium fg-dark">{variationOption?.variationType?.name}</span>
                        <span>{variationOption?.name}</span>
                    </ListGroup.Item>
                )
            })}
        </div>
    )
}

export default Variations;